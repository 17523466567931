import * as am4core from '@amcharts/amcharts4/core';
import { useAuth0 } from '@auth0/auth0-react';
import AdapterDateFns from '@date-io/date-fns';
import React from 'react';
import TagManager from 'react-gtm-module';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import StylesProvider from '@mui/styles/StylesProvider';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { LicenseInfo as MuiProLicenseInfo } from '@mui/x-license-pro/utils/licenseInfo';
import { AppCacheProvider } from '@truescope-web/react/lib/components/AppCache';
import UserStorageProvider from '@truescope-web/react/lib/components/UserStorageProvider';
import { SheetProvider as SheetProviderV2 } from '@truescope-web/react/lib/components/modal/SheetV2';
import { SnackbarProvider } from '@truescope-web/react/lib/components/modal/Snackbar';
import { InfoCardProvider } from '@truescope-web/react/lib/components/widgets/InfoCard';
import AppRoutes from './components/AppRoutes';
import ConfigProvider from './components/Config/ConfigProvider';
import CssThemeProvider from './components/CssThemeProvider';
import InfoCardComponents from './components/InfoCard/InfoCardComponents';
import WorkspaceValidation from './components/WorkspaceValidation';
import useSentryIo from './components/hooks/analytics/useSentryIo';
import SharedLoadingStateProvider from './components/hooks/useSharedLoadingState';
import { ApiLookupProvider } from './components/providers/ApiLookupProvider';
import DashboardDataProvider from './components/providers/DashboardDataProvider';
import FacebookUserTokenProvider from './components/providers/FacebookUserTokenProvider';
import WebSocketProvider from './components/providers/WebSockets/provider';
import './scss/themes/dark/theme.scss';
import './scss/themes/light/theme.scss';
import theme, { themes } from './theme';
import _Trends from './views/discovery/trends/Trends';
import ErrorBoundary from './views/error/ErrorBoundary';

const defaultUserSettings = {
	userSettings: {
		theme: themes.light
	},
	facebook: {
		skipFacebookAuth: false
	},
	inbox: {
		sizings: {
			mediaList: {
				//if screen width >= 1400, by default, insights should be expanded, media item list width = [screen width - (nav width + insights width)] * 40%
				width: window.innerWidth < 1400 ? 410 : (window.innerWidth - 360) * 0.4
			}
		}
	},
	workspaceSettings: {}
};

//initialize google tag manager
TagManager.initialize({
	gtmId: 'GTM-5GG8DDN',
	dataLayerName: 'PageDataLayer'
});

//initialize am4core
am4core.addLicense('CH228054555');
am4core.addLicense('MP325278338'); // maps
am4core.options.autoSetClassName = true;

//initialize mui pro
MuiProLicenseInfo.setLicenseKey(
	'dd87ef68b39ca535700dc98a799c2281Tz0xMDQwMDEsRT0xNzY1NDExMDk5MDAwLFM9cHJvLExNPXN1YnNjcmlwdGlvbixQVj1pbml0aWFsLEtWPTI='
);

const App = () => {
	return (
		<div className="app" id="app">
			<WebSocketProvider>
				<SheetProviderV2>
					<AppCacheProvider>
						<ConfigProvider>
							<FacebookUserTokenProvider>
								<DashboardDataProvider>
									<SnackbarProvider
										snackbarProps={{
											autoHideDuration: 12000
										}}
									>
										<SharedLoadingStateProvider>
											<WorkspaceValidation>
												<InfoCardProvider InfoCardComponents={InfoCardComponents}>
													<AppRoutes />
												</InfoCardProvider>
											</WorkspaceValidation>
										</SharedLoadingStateProvider>
									</SnackbarProvider>
								</DashboardDataProvider>
							</FacebookUserTokenProvider>
						</ConfigProvider>
					</AppCacheProvider>
				</SheetProviderV2>
			</WebSocketProvider>
		</div>
	);
};

/*
create the root app
*/
const AppRoot = () => {
	const { user } = useAuth0();
	useSentryIo();

	return (
		<ErrorBoundary>
			<StylesProvider injectFirst>
				<ThemeProvider theme={theme}>
					<ApiLookupProvider>
						<UserStorageProvider storageKey={user?.sub} defaultValues={defaultUserSettings}>
							<LocalizationProvider dateAdapter={AdapterDateFns}>
								<CssThemeProvider>
									<App />
								</CssThemeProvider>
							</LocalizationProvider>
						</UserStorageProvider>
					</ApiLookupProvider>
				</ThemeProvider>
			</StylesProvider>
		</ErrorBoundary>
	);
};

export default AppRoot;

export const exportedForTesting = {
	defaultUserSettings
};
