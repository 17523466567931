import { memo, useEffect } from 'react';
import { marketsLookup } from '@truescope-web/utils/lib/lookups';
import { isNullOrUndefined } from '@truescope-web/utils/lib/objects';
import { useConfigContext } from './Config/ConfigProvider';
import { updateWorkspace } from './Config/configReducer';
import { completeFirstLogin } from './WorkspaceValidationConstants';
import { useApiLookup } from './providers/ApiLookupProvider';
import { useDashboardDataContext } from './providers/DashboardDataProvider';

const WorkspaceValidation = ({ children }) => {
	const [{ workspace, isInitialized: isConfigContextInitialized, isLoading: isConfigContextLoading }, dispatchConfig] =
		useConfigContext();
	const dashboardDataContext = useDashboardDataContext();
	const [getClientApi] = useApiLookup();

	useEffect(() => {
		if (!dashboardDataContext.isInitialized || isConfigContextLoading || !isConfigContextInitialized || isNullOrUndefined(workspace)) {
			return;
		}

		if (workspace.userSettings?.awaitingFirstLogin) {
			const completeFirstLoginAsync = async () => {
				try {
					const updatedWorkspace = await completeFirstLogin(workspace, getClientApi, dashboardDataContext);
					dispatchConfig(updateWorkspace(updatedWorkspace));
				} catch (e) {
					console.error(`failed to complete first login - ${e.response || e.message}`, e);
				}
			};

			completeFirstLoginAsync();
		}

		if (isNullOrUndefined(workspace.userSettings?.showSyndication)) {
			dispatchConfig(
				updateWorkspace({
					userSettings: {
						...(workspace.userSettings || {}),
						showSyndication: workspace.market_id === marketsLookup.newZealand && workspace.content_syndication === 2
					}
				})
			);
		}
	}, [isConfigContextLoading, isConfigContextInitialized, workspace, dashboardDataContext, getClientApi]);

	return children;
};

export default memo(WorkspaceValidation);
