import { validationResults } from '@truescope-web/react/lib/utils/validation';
import { isNullOrUndefined } from '@truescope-web/utils/lib/objects';
import { stringIsNullOrEmpty } from '@truescope-web/utils/lib/strings';
import { imageAlignments } from '../../../components/Constants';
import {
	createEmptySchedule,
	createEmptyWebSetting,
	mediaItemSectionLayoutStyles,
	reportEmailTypes,
	reportLayoutStyles,
	reportSyndicationOptionsLookup,
	titleImageTypes
} from '../ReportConstants';

/**
 * Get default title image option
 * @param {{ badge, logo, banner }} images
 * @returns titleImageType
 */
export const getDefaultTitleImageOption = (images) => {
	if (!stringIsNullOrEmpty(images?.badge?.url)) {
		return titleImageTypes.badge;
	}
	if (!stringIsNullOrEmpty(images?.logo?.url)) {
		return titleImageTypes.logo;
	}
	if (!stringIsNullOrEmpty(images?.banner?.url)) {
		return titleImageTypes.banner;
	}
	return titleImageTypes.none;
};

/**
 * creates a new empty report template
 */
export const createNewReportTemplate = (user, defaultValues = {}) => {
	const { layout_settings, item_options, sections, subscriptions, collaborators, schedule, web_settings, ...other } = defaultValues;
	return {
		report_template_id: undefined,
		name: '',
		subtitle: '{long_date}',
		subject: null,
		layout_settings: {
			include_in_this_report: true,
			layout_style: reportLayoutStyles.standard,
			show_subtitle: true,
			show_name: true,
			title_image_type: titleImageTypes.badge,
			format: reportEmailTypes.email,
			hide_subscription: false,
			...(layout_settings || {})
		},
		web_settings: web_settings || createEmptyWebSetting(),
		item_options: {
			remove_duplicate_items: false,
			image_alignment: imageAlignments.left,
			layout_style: mediaItemSectionLayoutStyles.magazine,
			include_audience: true,
			include_engagement: false,
			include_likes: false,
			include_comments: false,
			include_shares: false,
			include_views: false,
			include_sentiment: true,
			include_keywords: false,
			include_contextual_summary: false,
			include_source_location: false,
			include_view_original: false,
			include_ave: false,
			override_default_settings: false,
			share_linkedin: false,
			share_twitter: false,
			share_email: false,
			share_facebook: false,
			language_code: null,
			serialized_filters: {
				sort: 'item_date',
				desc: true
			},
			syndication_display: reportSyndicationOptionsLookup.none,
			...(item_options || {})
		},
		sections: sections || [],
		subscriptions:
			subscriptions ||
			(stringIsNullOrEmpty(user.email)
				? []
				: [
						{
							recipient_email: user.email,
							is_subscribed: true
						}
				  ]),
		collaborators: collaborators || [],
		schedule: schedule || createEmptySchedule(false),
		reply_to: '',
		...other
	};
};

/**
 * icons used for report template templates
 */
export const reportTemplateTemplateIcons = {
	defaultTemplate: `${process.env.PUBLIC_URL}/images/widgets/widget-blank-report.png`,
	emptyTemplate: `${process.env.PUBLIC_URL}/images/widgets/widget-blank-report.png`
};

/**
 * validation rules for creating a report template in the report template builder
 */
export const reportTemplateBuilderValidationRules = {
	onValidate: {
		templateId: (newValue, _existingValue, _form) => {
			return isNullOrUndefined(newValue) ? validationResults.required('Please select a template') : validationResults.ok();
		},
		'reportTemplate.workspace_theme_id': (newValue, _existingValue, _form) => {
			return isNullOrUndefined(newValue) ? validationResults.required('Please select a theme') : validationResults.ok();
		},
		name: (newValue, _existingValue, _form) => {
			return stringIsNullOrEmpty(newValue)
				? validationResults.required('Please enter a report template name')
				: validationResults.ok();
		}
	}
};

/**
 * validation rules for adding external content
 */
export const externalContentBuilderValidationRules = {
	onValidate: {
		'item.title': (newValue, _existingValue, _form) => {
			return stringIsNullOrEmpty(newValue) ? validationResults.required('Please enter a content title') : validationResults.ok();
		},
		itemDate: (newValue, _existingValue, _form) => {
			return stringIsNullOrEmpty(newValue) ? validationResults.required('Please enter a date') : validationResults.ok();
		}
	}
};
