import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import Tooltip from '@mui/material/Tooltip';
import { useAppCache } from '@truescope-web/react/lib/components/AppCache';
import Select from '@truescope-web/react/lib/components/form/Select';
import TextField from '@truescope-web/react/lib/components/form/TextField';
import ValidationPlaceholder from '@truescope-web/react/lib/components/form/ValidationPlaceholder';
import Grid from '@truescope-web/react/lib/components/layout/Grid';
import { horizontalAlignment } from '@truescope-web/react/lib/components/layout/Inline';
import Typography from '@truescope-web/react/lib/components/layout/Typography';
import { useClickAwayContext } from '@truescope-web/react/lib/components/modal/SheetV2';
import { snackbarVariants, useSnackbar } from '@truescope-web/react/lib/components/modal/Snackbar';
import ActionButtonsGroup from '@truescope-web/react/lib/components/widgets/ActionButtonsGroup';
import { validateObject, validationResults } from '@truescope-web/react/lib/utils/validation';
import { arrayIsNullOrEmpty } from '@truescope-web/utils/lib/arrays';
import { marketsLookup } from '@truescope-web/utils/lib/lookups';
import { isNullOrUndefined } from '@truescope-web/utils/lib/objects';
import { stringIsNullOrEmpty } from '@truescope-web/utils/lib/strings';
import { extractError } from '../../../components/Api';
import { useConfigContext } from '../../../components/Config/ConfigProvider';
import SheetLayout from '../../../components/layout/SheetLayout';
import { useApiLookup } from '../../../components/providers/ApiLookupProvider';
import SelectTheme from '../../../components/widgets/SelectTheme';
import { createReportTemplate, getTitleImageOptions, titleImageTypeOptions } from '../ReportConstants';
import { syncReportTemplateToConfig } from '../provider/constants';
import { createNewReportTemplate, getDefaultTitleImageOption, reportTemplateTemplateIcons } from './ReportTemplateBuilderConstants';
import defaultTemplate from './defaultTemplate';

/**
 * template widgets available for use
 */
const reportTemplateTemplates = [defaultTemplate];

const reportTemplateValidationRules = {
	name: (reportTemplate) => {
		return stringIsNullOrEmpty(reportTemplate.name) ? validationResults.required() : validationResults.ok();
	},
	workspaceThemeId: (reportTemplate) => {
		return isNullOrUndefined(reportTemplate.workspace_theme_id) ? validationResults.required() : validationResults.ok();
	}
};

const ReportTemplateBuilder = ({ isOpen }) => {
	const clickAwayContext = useClickAwayContext();
	const [reportTemplate, setReportTemplate] = useState(undefined);
	const [isDirty, setIsDirty] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [{ workspace, user }, dispatchConfig] = useConfigContext();
	const [activeStep, setActiveStep] = useState(-1);
	const [templateId, setTemplateId] = useState(defaultTemplate.id);
	const [steps, setSteps] = useState([]);
	const [completedSteps, setCompletedSteps] = useState([]);
	const [validationState, setValidationState] = useState({ success: true });
	const [onCreate, setOnCreate] = useState(null);
	const themes = workspace.themes || [];
	const [titleImageOptions, setTitleImageOptions] = useState(titleImageTypeOptions);
	const { showSnackbar } = useSnackbar();
	const [getClientApi] = useApiLookup();
	const navigate = useNavigate();
	const appCache = useAppCache();

	useEffect(() => {
		if (isOpen) {
			setReportTemplate(
				createNewReportTemplate(user, {
					//default settings
					workspace_id: workspace.workspace_id,
					reply_to: user.email,
					item_options: {
						include_source_location: workspace?.market_id === marketsLookup.northAmerica
					}
				})
			);
		}
	}, [isOpen, user, setReportTemplate, workspace.workspace_id, workspace.exchangeRate?.currencyCode, workspace?.market_id]);

	useEffect(() => {
		setTitleImageOptions(getTitleImageOptions(themes[0]));
	}, []);

	useEffect(() => {
		clickAwayContext.setShowClickAway(isDirty);
	}, [isDirty, clickAwayContext.setShowClickAway]);

	useEffect(() => {
		/*
		there's a bug with the scrollView. It doesn't like the sideways scroll in/out
		but a window resize seems to counter the bug and fix the window, so after a step change,
		we trigger a resize
		*/
		setTimeout(() => {
			window.dispatchEvent(new Event('resize'));
		}, 500);
	}, [activeStep]);

	const isInitialStep = arrayIsNullOrEmpty(steps) || activeStep < 0;

	const isValid = () => {
		const newValidationState = validateObject(reportTemplate, reportTemplateValidationRules);
		setValidationState(newValidationState);
		return newValidationState;
	};

	const handleSave = async () => {
		const { success } = isValid();
		if (!success) {
			return;
		}

		if (!isNullOrUndefined(onCreate)) {
			onCreate(reportTemplate, {});
		}

		reportTemplate.subject = reportTemplate.name;

		try {
			setIsLoading(true);
			const { reportTemplate: newReportTemplate } = await createReportTemplate(
				getClientApi,
				reportTemplate,
				workspace,
				appCache,
				user
			);
			syncReportTemplateToConfig(newReportTemplate, workspace, dispatchConfig);
			showSnackbar(`Report created successfully`, snackbarVariants.success);
			navigate(`/w/${workspace.workspace_id}/reports/templates/${newReportTemplate.report_template_id}`);
		} catch (e) {
			const msg = `failed to create report - ${extractError(e)}`;
			console.error(msg, e);
			showSnackbar(msg, snackbarVariants.error);
		}
		setIsLoading(false);
		clickAwayContext.closeSheet();
	};

	const handleClearSteps = () => {
		setValidationState({});
		setTemplateId(null);
		setSteps([]);
		setCompletedSteps([]);
		setActiveStep(-1);
	};

	const handlePrevious = () => {
		if (activeStep === 0) {
			handleClearSteps();
		} else {
			completedSteps[activeStep] = false;
			setCompletedSteps(completedSteps);
			setActiveStep(activeStep - 1);
		}
	};

	const handleNext = () => {
		if (activeStep >= steps.length - 1) {
			return;
		}

		const nextCompletedSteps = [...completedSteps];
		nextCompletedSteps[activeStep] = true;
		setCompletedSteps(nextCompletedSteps);
		setActiveStep(activeStep + 1);
	};

	const handleCancel = () => {
		handleClearSteps();
		clickAwayContext.closeSheetWithClickAway();
	};

	const handleTemplateChange = ({ id, onCreate, setDefaultProperties, steps: newSteps }) => {
		setIsDirty(true);
		setTemplateId(id);
		setReportTemplate(setDefaultProperties(user, reportTemplate));
		setOnCreate(onCreate || null);
		setActiveStep(-1);
		if (!arrayIsNullOrEmpty(newSteps)) {
			setSteps(newSteps);
			setCompletedSteps(newSteps.map(() => false));
		} else {
			setSteps([]);
			setCompletedSteps([]);
		}
	};

	const handleThemeChange = (_e, themeId) => {
		const currentTheme = themes.find((x) => x.workspace_theme_id === themeId);
		setTitleImageOptions(getTitleImageOptions(currentTheme));
		setReportTemplate((prev) => {
			const updatedReportTemplate = {
				...prev,
				workspace_theme_id: themeId
			};

			const { images } = currentTheme;
			// Change Title header option if we switch to a theme that doesn't have an image available for that option.
			const currentTitleImageOption = reportTemplate.layout_settings.title_image_type;
			if (isNullOrUndefined(images[currentTitleImageOption]?.url)) {
				updatedReportTemplate.layout_settings = {
					...updatedReportTemplate.layout_settings,
					title_image_type: getDefaultTitleImageOption(images)
				};
			}

			return updatedReportTemplate;
		});
	};

	const handleNameChange = (e) => {
		setReportTemplate((prev) => ({
			...prev,
			name: e.target.value,
			subject: e.target.value
		}));
	};

	const handleTitleImageTypeChange = (_e, value) => {
		setReportTemplate((prev) => ({
			...prev,
			layout_settings: {
				...prev.layout_settings,
				title_image_type: value
			}
		}));
	};

	const renderTemplate = (template, index, selected) => {
		const TemplateIcon = reportTemplateTemplateIcons[template.icon] || reportTemplateTemplateIcons.defaultTemplate;
		return (
			<Tooltip disableInteractive arrow title="Click to select" key={template.id}>
				<div
					key={index}
					id={`${template.name}_button`}
					tabIndex="0"
					role="button"
					aria-pressed="false"
					className={`widget-selector__widget-container__widget ${
						selected ? 'widget-selector__widget-container__widget--selected' : ''
					}`}
					onClick={() => handleTemplateChange(template)}
				>
					<img src={TemplateIcon} alt={template.name} width="100%" />
					<Typography className="widget-selector__widget-container__widget__label" variant="subtitle" bold>
						{template.name}
					</Typography>
				</div>
			</Tooltip>
		);
	};

	const renderTemplateSelector = () => {
		return (
			<div className="widget-selector">
				<Grid container>
					<Grid item>
						<Typography variant="subtitle">Select a template type</Typography>
					</Grid>
					<Grid item>
						<div className="widget-selector__widget-container">
							{reportTemplateTemplates.map((rtt, index) => renderTemplate(rtt, index, templateId === rtt.id))}
						</div>
					</Grid>
				</Grid>
			</div>
		);
	};

	const renderToolbarButtons = () => {
		const isComplete = completedSteps.slice(0, completedSteps.length - 1).every((completed) => completed);
		const isOnFinalStep = activeStep === steps.length - 1;

		const backButtonProps = {
			onClick: handlePrevious
		};

		const primaryButtonProps = isOnFinalStep
			? {
					onClick: handleSave,
					disabled: !isComplete
			  }
			: {
					label: 'Next',
					onClick: handleNext
			  };

		return (
			<ActionButtonsGroup
				disabled={isLoading}
				onCancel={handleCancel}
				primaryButtonProps={primaryButtonProps}
				secondaryButtonProps={!arrayIsNullOrEmpty(steps) ? backButtonProps : null}
				buttonsAlignment={horizontalAlignment.left}
			/>
		);
	};

	const renderStepper = () => {
		return (
			<Stepper className="stepper" nonLinear activeStep={activeStep}>
				{steps.map(({ step }, index) => (
					<Step key={step.label} classes={{ horizontal: 'stepper__horizontal__first-child--no-padding-left' }}>
						<StepLabel completed={`${completedSteps[index] && activeStep !== index}`}>{step.label}</StepLabel>
					</Step>
				))}
			</Stepper>
		);
	};

	const renderSteps = () => {
		const { step } = steps[activeStep];
		return (
			<TransitionGroup component={null}>
				<CSSTransition unmountOnExit={false} key={step.label} classNames="slide-right" timeout={255}>
					<step.component />
				</CSSTransition>
			</TransitionGroup>
		);
	};

	const renderLayout = () => (
		<SheetLayout header="Create Report Template" footer={renderToolbarButtons()} withTransition isLoading={isLoading}>
			{isInitialStep ? (
				<Grid container spacing={3}>
					<Grid item>
						<Typography variant="subtitle">Enter the name of your report</Typography>
					</Grid>
					<Grid item>
						<TextField value={reportTemplate?.name} onChange={handleNameChange} placeholder="Enter a report title" />
						<ValidationPlaceholder validationResult={validationState?.results?.name} />
					</Grid>
					<Grid item>{renderTemplateSelector()}</Grid>
					<Grid item sm={12}>
						<SelectTheme
							value={reportTemplate?.workspace_theme_id}
							onChange={handleThemeChange}
							onClickManageThemes={() => clickAwayContext.closeSheetWithClickAway()}
							validationResult={validationState.workspaceThemeId}
						/>
						<ValidationPlaceholder validationResult={validationState?.results?.workspaceThemeId} />
					</Grid>
					<Grid item sm={12}>
						<Grid container>
							<Grid item>
								<Typography variant="subtitle">Choose header image</Typography>
							</Grid>
							<Grid item>
								<Select
									options={titleImageOptions}
									value={reportTemplate.layout_settings.title_image_type}
									onChange={handleTitleImageTypeChange}
									fullWidth={false}
								/>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			) : (
				<Grid container spacing={4}>
					<Grid item>{renderStepper()}</Grid>
					<Grid item>{renderSteps()}</Grid>
				</Grid>
			)}
		</SheetLayout>
	);

	return (
		<div className="full-height">
			{isNullOrUndefined(reportTemplate) ? <SheetLayout header="Create Report Template" isLoading /> : renderLayout()}
		</div>
	);
};

export default ReportTemplateBuilder;
