import cloneDeep from 'lodash.clonedeep';
import moment from 'moment';
import 'moment/min/locales';
import { snackbarVariants } from '@truescope-web/react/lib/components/modal/Snackbar';
import { wasTokenCancelled } from '@truescope-web/react/lib/hooks/useCancelToken';
import { validationResults } from '@truescope-web/react/lib/utils/validation';
import { validateEmail } from '@truescope-web/react/lib/utils/validationFunctions';
import { arrayIsNullOrEmpty } from '@truescope-web/utils/lib/arrays';
import { getEnvironmentName } from '@truescope-web/utils/lib/constants';
import { dateOptions, dateOptionsLookup } from '@truescope-web/utils/lib/dates';
import { createRegexKeyword, getHostedIconUrl, getHostedImageUrl } from '@truescope-web/utils/lib/mediaItem';
import { mediaTypesLookup } from '@truescope-web/utils/lib/mediaTypes';
import { isNullOrUndefined } from '@truescope-web/utils/lib/objects';
import { joinDefined, sortByString, stringIsNullOrEmpty } from '@truescope-web/utils/lib/strings';
import { extractError } from '../../components/Api';
import { getTimeZoneName, imageAlignments, scheduleIntervalOptionsLookup } from '../../components/Constants';
import { filterDefinitions } from '../../components/widgets/FilterComponents';
import { deserializeFilters, filterHasValue, getFilterPaths, serializeFilters } from '../../components/widgets/FilterConstants';
import { serializeChart } from '../../components/widgets/charts/ChartConstants';
import { createFilterOptions } from '../../components/widgets/selectedFilters/FiltersPopoverConstants';

/**
 * time format for schedule
 */
export const TIME_FORMAT = 'HH:mm';
/**
 * MM 	01 02 ... 11 12
 * DD 	01 02 ... 30 31
 * YYYY 1970 1971 ... 2029 2030
 * These formats are used as merge formats in report service
 */
export const US_SHORT_DATE_FORMAT = 'MM/dd/yyyy';
export const SHORT_DATE_FORMAT = 'dd/MM/yyyy';
/**
 * different types of content that report sections can be
 */
export const reportSectionTypes = {
	mediaItems: 'mediaitems',
	html: 'html',
	charts: 'charts',
	aiSummary: 'aiSummary'
};

/**
 * Report section types friendly names
 */
export const reportSectionTypeFriendlyNames = {
	mediaitems: 'Media Items',
	html: 'HTML',
	charts: 'Charts',
	aiSummary: 'Automated AI Summary'
};

/**
 * content source options for ai html sections
 */
export const aiHtmlSectionDataSourceLookup = {
	report: 'report',
	workspace: 'workspace',
	llm: 'llm'
};

/**
 * summary types for ai html sections
 */
export const alterTextSummaryLookup = {
	improve: 'improve text',
	shorten: 'shorten text',
	expand: 'expand text'
};

const aiSummaryTimePeriodOptionsList = [
	{ value: dateOptionsLookup.custom, label: 'Since the previous report was sent' },
	dateOptionsLookup.today,
	dateOptionsLookup.last24Hours,
	dateOptionsLookup.last2Days,
	dateOptionsLookup.last3Days,
	dateOptionsLookup.last7Days,
	dateOptionsLookup.last14Days,
	dateOptionsLookup.last30Days,
	dateOptionsLookup.last60Days,
	dateOptionsLookup.last90Days
];
export const aiSummaryDateOptions = aiSummaryTimePeriodOptionsList.map((option) => {
	if (!isNullOrUndefined(option.label)) {
		return option;
	}

	return dateOptions.find((dateOption) => dateOption.value === option);
});

/**
 * styles that dictate the layout of a media item section
 */
export const mediaItemSectionLayoutStyles = {
	magazine: 'magazine',
	//under the hood, this needs to remain as headline
	compact: 'headline'
};

/**
 * options for the layout of a media item section
 */
export const mediaItemSectionLayoutStyleOptions = [
	{ label: 'Magazine', value: mediaItemSectionLayoutStyles.magazine },
	{ label: 'Compact', value: mediaItemSectionLayoutStyles.compact }
];

/**
 * styles that dictate a report layout
 */
export const reportLayoutStyles = {
	standard: 'standard',
	wide: 'wide'
};

/**
 * options for the styles of a report layout
 */
export const layoutStyleOptions = [
	{ label: 'Standard (recommended for most email clients)', value: reportLayoutStyles.standard },
	{ label: 'Wide', value: reportLayoutStyles.wide }
];

/**
 * formats for a report email
 */
export const reportEmailTypes = {
	email: 'email',
	web: 'web'
};

/**
 * options for the styles of a report email layout
 */
export const emailFormatOptions = [
	{ label: 'Include report content in the email', value: reportEmailTypes.email },
	{ label: 'Link to web hosted report (recommended when including Word and PDF formats)', value: reportEmailTypes.web }
];

/**
 * options report type
 */
export const reportFormatOptions = [
	{
		label: (
			<span>
				<b>Word</b> - Link to a downloadable report in docx format
			</span>
		),
		webSettingField: 'include_report_docx_url'
	},
	{
		label: (
			<span>
				<b>PDF</b> - Link to a downloadable report in pdf format
			</span>
		),
		webSettingField: 'include_report_pdf_url'
	},
	{
		label: (
			<span>
				<b>CSV</b> - Link to a spreadsheet containing all media items in this report
			</span>
		),
		webSettingField: 'include_spreadsheet_link'
	}
];

/**
 * type of image that can be used on a report
 */
export const titleImageTypes = {
	logo: 'logo',
	banner: 'banner',
	badge: 'badge',
	none: 'none'
};
/**
 * type of image that can be used on a report
 */
export const titleImageDimensions = {
	badge: {
		height: 200,
		width: 200
	},
	logo: {
		height: 440,
		width: 500
	},
	banner: {
		width: 800
	}
};

/**
 * options for the type of the image used on a report
 */
export const titleImageTypeOptions = [
	{ label: 'Badge', value: titleImageTypes.badge },
	{ label: 'Logo', value: titleImageTypes.logo },
	{ label: 'Banner', value: titleImageTypes.banner },
	{ label: 'None', value: titleImageTypes.none }
];

/**
 * Gets a set of title image options based on whether an image is available in the theme.
 * @param {*} theme - the theme object
 * @returns {Array} - options, where null image urls will be disabled.
 */
export const getTitleImageOptions = (theme) => {
	const { images } = theme;

	return [
		{ label: 'None', value: titleImageTypes.none },
		{
			label: `Badge${isNullOrUndefined(images.badge?.url) ? ' (Disabled: add a badge to this theme)' : ''}`,
			value: titleImageTypes.badge,
			isDisabled: isNullOrUndefined(images.badge?.url)
		},
		{
			label: `Logo${isNullOrUndefined(images.logo?.url) ? ' (Disabled: add a logo to this theme)' : ''}`,
			value: titleImageTypes.logo,
			isDisabled: isNullOrUndefined(images.logo?.url)
		},
		{
			label: `Banner${isNullOrUndefined(images.banner?.url) ? ' (Disabled: add a banner to this theme)' : ''}`,
			value: titleImageTypes.banner,
			isDisabled: isNullOrUndefined(images.banner?.url)
		}
	];
};

/**
 * Takes a string and escapes any characters where regex will complain if they're not escaped.
 * @param {string} string
 * @returns {string} escaped regex string
 */
// NOTE
const escapeRegExp = (string) => string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

/**
 * converts a section item to a ui-friendly object
 * @param {*} item
 */
export const convertSectionItem = (item) => {
	let state = {};
	state.item_date__moment = moment(item.item_date);
	state.item_date__date = state.item_date__moment.toDate();
	state.publishedDate_string = state.item_date__moment.format('DD MMMM YYYY h:mma');

	if (!arrayIsNullOrEmpty(item.keyword_matches)) {
		const keywords = item.keyword_matches.map(({ keyword }) => escapeRegExp(`${keyword}`));
		const keywordMatches = [];
		keywords.forEach((keyword) => {
			try {
				keywordMatches.push(createRegexKeyword(keyword));
			} catch (e) {
				console.error(`Regex error (${e.message}) while converting keyword: '${keyword}' for item ${item.item_id}\r\n`);
			}
		});
		state.keywordMatches = keywordMatches;
		state.keywordMatches_string = joinDefined(keywords, ', ');
		delete item.keyword_matches;
	}

	state.image_url = item.media_type === mediaTypesLookup.facebook ? stripFacebookCDN(item.image_url) : item.image_url;

	const images = setItemImages(item.image_url);

	if (item.type === reportTemplateSectionItemType.externalItem && stringIsNullOrEmpty(item.source_profile_image)) {
		item.source_profile_image = getHostedIconUrl(item.url);
	}

	state.contentRightNames = (item.content_rights || []).map(({ name }) => name);

	return {
		...item,
		...state,
		...images
	};
};

export const stripFacebookCDN = (imageUrl) => {
	if (stringIsNullOrEmpty(imageUrl)) {
		return imageUrl;
	}

	try {
		const url = new URL(decodeURIComponent(imageUrl));
		const urlParams = new URLSearchParams(url.search);
		const urlQueryString = urlParams.get('url');
		if (stringIsNullOrEmpty(urlQueryString)) {
			return imageUrl;
		}

		return urlQueryString;
	} catch (e) {
		return imageUrl;
	}
};

export const setItemImages = (imageUrl, cacheBust = false) => {
	if (stringIsNullOrEmpty(imageUrl)) {
		return imageUrl;
	}

	const other = getHostedImageUrl(imageUrl, 150, 150, cacheBust);
	return {
		resized_images: {
			wide: {
				full: getHostedImageUrl(imageUrl, 800, 360, cacheBust),
				left: other,
				right: other
			},
			standard: {
				full: getHostedImageUrl(imageUrl, 600, 310, cacheBust),
				left: other,
				right: other
			}
		}
	};
};

const deserializeChartSection = (section, workspace) => {
	section.charts = (section.charts || [])
		.map((reportChart) => deserializeReportChart(reportChart, workspace))
		.filter((reportChart) => !isNullOrUndefined(reportChart));

	return section;
};

const deserializeMediaItemsSection = (section, workspace) => {
	section.serialized_filters = deserializeFilters(section.serialized_filters, workspace);
	section.serialized_filters.sort =
		isNullOrUndefined(section.serialized_filters?.sort) || section.serialized_filters.sort === 'publication_date'
			? 'item_date'
			: section.serialized_filters.sort;

	section.serialized_filters.desc = !isNullOrUndefined(section.serialized_filters?.desc) ? section.serialized_filters.desc : true;

	section.filter_options = createFilterOptions(
		reportTemplatesFilterFields,
		[],
		getFilterPaths(section.serialized_filters),
		workspace.market_id
	);

	if (isNullOrUndefined(section.total_item_count)) {
		section.total_item_count = 0;
	}

	if (stringIsNullOrEmpty(section.layout_settings.image_alignment)) {
		section.layout_settings.image_alignment = imageAlignments.right;
	}

	if (stringIsNullOrEmpty(section.layout_settings.syndication_display)) {
		section.layout_settings.syndication_display = reportSyndicationOptionsLookup.none;
	}

	//todo: remove this, it should be empty anyway
	// section.items = (section.items || []).map((item) => convertSectionItem(item));
	section.items = [];

	// MY-2363 (from 22/4/2024) remove share_email, share_facebook, share_linkedin, share_twitter, and language_code from overridable settings
	delete section.layout_settings.share_email;
	delete section.layout_settings.share_facebook;
	delete section.layout_settings.share_linkedin;
	delete section.layout_settings.share_twitter;
	delete section.layout_settings.language_code;

	return section;
};

const deserializeHtmlSection = (section) => {
	if (isNullOrUndefined(section.layout_settings)) {
		section.layout_settings = {
			show_title: false
		};
	}
	return section;
};

const deserializeAiSummarySection = ({ serialized_filters, ...section }, workspace) => {
	section.serialized_filters = deserializeFilters(serialized_filters, workspace);
	delete section.summary_type; //todo: remove this later
	return section;
};

/**
 * filters that define what can be picked
 */
export const reportTemplatesFilterFields = [
	filterDefinitions.title,
	filterDefinitions.title_summary,
	filterDefinitions.title_summary_body,
	filterDefinitions['simple_query.or'],
	filterDefinitions['simple_query.and'],
	filterDefinitions['simple_query.not'],
	filterDefinitions.languages,
	filterDefinitions.media_types,
	filterDefinitions.query_ids,
	filterDefinitions.sentiments,
	filterDefinitions.majorMentions,
	filterDefinitions.sources,
	filterDefinitions.authors,
	filterDefinitions.categories,
	filterDefinitions.countries,
	filterDefinitions.regions,
	filterDefinitions.cities,
	filterDefinitions.locations,
	filterDefinitions.audience,
	filterDefinitions.social_engagement,
	filterDefinitions.people,
	filterDefinitions.companies,
	filterDefinitions.entity_sentiments,
	filterDefinitions.source_dmarank,
	filterDefinitions.source_networks,
	filterDefinitions.source_sections
].map(({ option }) => option.value);

/**
 * Filters that are preselected but still removable
 */
export const contentRulesPreselectedFilters = [filterDefinitions.query_ids].map(({ option }) => ({
	field: option.value,
	removable: true
}));

const deserializeReportTemplate = (data, workspace) => {
	let { reportTemplate, dateUpdated, lastPublishedDate, message } = data;

	if (!stringIsNullOrEmpty(message)) {
		return { message };
	}

	reportTemplate.layout_settings.merge_short_date_format = SHORT_DATE_FORMAT;
	reportTemplate.layout_settings.merge_us_short_date_format = US_SHORT_DATE_FORMAT;
	reportTemplate.layout_settings.merge_long_date_format = getLocaleDateString();

	if (stringIsNullOrEmpty(reportTemplate.item_options.layout_style)) {
		reportTemplate.item_options.layout_style = mediaItemSectionLayoutStyles.magazine;
	}

	// To backfill old reports with no format section
	if (stringIsNullOrEmpty(reportTemplate.layout_settings.format)) {
		reportTemplate.layout_settings.format = reportEmailTypes.email;
	}

	if (isNullOrUndefined(reportTemplate.web_settings)) {
		reportTemplate.web_settings = createEmptyWebSetting();
	}

	reportTemplate.sections = (reportTemplate.sections || []).map((section) => {
		switch (section.section_type) {
			case reportSectionTypes.mediaItems:
				return deserializeMediaItemsSection(section, workspace);
			case reportSectionTypes.html:
				return deserializeHtmlSection(section);
			case reportSectionTypes.charts:
				return deserializeChartSection(section, workspace);
			case reportSectionTypes.aiSummary:
				return deserializeAiSummarySection(section, workspace);
			default:
				throw new Error(`unknown report section type '${section.section_type}'`);
		}
	});

	// sort collaborators from API response, might be able to remove later
	if (!arrayIsNullOrEmpty(reportTemplate.collaborators)) {
		reportTemplate.collaborators = sortByString(reportTemplate.collaborators, 'name');
	}

	if (isNullOrUndefined(reportTemplate.schedule)) {
		reportTemplate.schedule = createEmptySchedule(false);
	} else {
		//instantiate the times to moment
		reportTemplate.schedule.times_of_day__moment = (reportTemplate.schedule.times_of_day || []).map((time) =>
			moment(time, TIME_FORMAT)
		);
	}

	// Theming - backwards compatibility - very basic and only needed while we haven't run the
	// migrator script, which will create themes based on the old properties in report templates.
	// TODO: This can be removed once that is done.
	if (reportTemplate.workspace_theme_id === 0) {
		console.warn("Workspace theme ID undefined. This report hasn't been migrated correctly", reportTemplate);
		const themes = workspace.themes;

		// Default to Truescope if exists, else first in list.
		const theme = themes.find((x) => x.name === 'Truescope');
		reportTemplate.workspace_theme_id = isNullOrUndefined(theme) ? themes[0]?.workspace_theme_id : theme.workspace_theme_id;
	}

	//language codes are now case sensitive and upper. remove this by 2023
	if (!stringIsNullOrEmpty(reportTemplate.item_options.language_code)) {
		reportTemplate.item_options.language_code = reportTemplate.item_options.language_code.toUpperCase();
	}

	if (stringIsNullOrEmpty(reportTemplate.item_options.image_alignment)) {
		reportTemplate.item_options.image_alignment = imageAlignments.right;
	}

	if (stringIsNullOrEmpty(reportTemplate.item_options.syndication_display)) {
		reportTemplate.item_options.syndication_display = reportSyndicationOptionsLookup.none;
	}

	// before this change (19/10/2022), sort is set to "publication_date",
	// which caused the media items were not displayed in the right order by date
	const itemOptionSort = reportTemplate.item_options.serialized_filters?.sort;
	if (stringIsNullOrEmpty(itemOptionSort) || itemOptionSort === 'publication_date') {
		reportTemplate.item_options = {
			...reportTemplate.item_options,
			serialized_filters: {
				...(reportTemplate.item_options.serialized_filters || {}),
				sort: 'item_date',
				desc: true
			}
		};
	}

	//a bug that occurred around 12/06/2024, filters missing the desc property produces really weird sort order if you try to move items around manually
	//if Con runs a script to fix all reports with missing desc property, it could fix this. otherwise for now, just easy to do this.
	if (isNullOrUndefined(reportTemplate.item_options.serialized_filters.desc)) {
		reportTemplate.item_options.serialized_filters.desc = true;
	}

	return {
		dateUpdated,
		lastPublishedDate,
		reportTemplate,
		message: null
	};
};

/**
 * drag/drop an item
 */
export const onDragDropMediaItem = async (
	getClientApi,
	workspace_id,
	report_template_id,
	report_template_section_id,
	report_template_section_item_id,
	pin_before_id,
	pin_after_id
) => {
	const api = await getClientApi();
	const { data } = await api.post(
		`reports/v1/templates/${report_template_id}/sections/${report_template_section_id}/section-items/${report_template_section_item_id}`,
		{
			workspace_id,
			pin_before_id,
			pin_after_id
		}
	);
	if (!stringIsNullOrEmpty(data.message)) {
		throw new Error(data.message);
	}
	return data;
};

/**
 *
 * @param {*} format
 * L: "DD.MM.YYYY"
 * LL: "D MMMM YYYY"
 * LLL: "D MMMM YYYY HH:mm"
 * LLLL: "dddd, D MMMM YYYY HH:mm"
 * LT: "HH:mm"
 * LTS: "HH:mm:ss"
 *
 */
const getLocaleDateString = (long = true) => {
	// https://github.com/moment/moment/issues/2505
	const localeData = moment.localeData(window.navigator.userLanguage || window.navigator.language),
		llll = localeData.longDateFormat('LLLL'),
		lll = localeData.longDateFormat('LLL'),
		ll = localeData.longDateFormat('LL'),
		l = localeData.longDateFormat('L');

	const result = long ? llll.replace(lll.replace(ll, ''), '') : l;

	// We replace because the backend expects it like this.
	return result.replaceAll('D', 'd').replaceAll('m', 'M').replaceAll('Y', 'y');
};

/**
 * loads a report template from the server
 */
export const getReportTemplate = async (getClientApi, cancelToken, report_template_id, workspace) => {
	try {
		const api = await getClientApi();
		const { data } = await api.get(`reports/v1/${workspace.workspace_id}/templates/${report_template_id}`, { cancelToken });
		if (!stringIsNullOrEmpty(data.message)) {
			throw new Error(data.message);
		}
		return deserializeReportTemplate(data, workspace);
	} catch (e) {
		if (wasTokenCancelled(cancelToken)) {
			throw e;
		}
		throw new Error(`Failed to get report template: ${extractError(e)}`);
	}
};

/**
 * publishes (or previews) a report
 */
export const publishReportTemplate = async (getClientApi, workspace_id, report_template_id, preview, email) => {
	const api = await getClientApi();

	const { data } = await api.post(`reports/v1/${workspace_id}/templates/${report_template_id}/publish`, { preview, email });

	if (!stringIsNullOrEmpty(data.message)) {
		throw new Error(data.message);
	}

	return data;
};

/**
 * gets report templates from the server
 * @param {*} param0
 * @param {*} query_id
 */
export const getReportTemplates = async (getClientApi, workspace_id) => {
	const api = await getClientApi();
	const { data } = await api.post(`reports/v1/templates`, { workspace_id });
	if (!stringIsNullOrEmpty(data.message)) {
		throw new Error(data.message);
	}
	return data;
};

/**
 * deletes a report template
 * @param {*} param0
 * @param {*} query_id
 */
export const removeReportTemplate = async (getClientApi, report_template_id, workspaceId) => {
	const api = await getClientApi();
	const { data } = await api.delete(`reports/v1/${workspaceId}/templates/${report_template_id}`);
	if (!stringIsNullOrEmpty(data.message)) {
		throw new Error(data.message);
	}
	return data;
};

/**
 * saves a report template
 */
export const saveReportTemplate = async (getClientApi, cancelToken, reportTemplate, workspace, appCache, user) => {
	try {
		const api = await getClientApi();
		const { data } = await api.patch(
			`reports/v1/templates/${reportTemplate.report_template_id}`,
			{
				reportTemplate: serializeReportTemplate(reportTemplate, workspace, appCache, user, false)
			},
			{ cancelToken }
		);
		if (!stringIsNullOrEmpty(data.message)) {
			throw new Error(data.message);
		}
		return data;
	} catch (e) {
		if (wasTokenCancelled(cancelToken)) {
			return { cancelled: true };
		}
		throw new Error(`Failed to save report template - ${extractError(e)}`);
	}
};

/**
 * creates a report template
 * @param {*} param0
 * @param {*} reportTemplate
 */
export const createReportTemplate = async (getClientApi, reportTemplate, workspace, appCache, user) => {
	const api = await getClientApi();
	const { data } = await api.put('reports/v1/templates', {
		reportTemplate: serializeReportTemplate(reportTemplate, workspace, appCache, user, false)
	});
	if (!stringIsNullOrEmpty(data.message)) {
		throw new Error(data.message);
	}
	return deserializeReportTemplate(data, workspace);
};

/**
 * adds a section to the top of a report
 * @param {getClientApi} getClientApi
 * @param {number} report_template_id
 * @param {section[]} sections
 * @param {workspace} workspace
 *
 * @returns void
 */
export const addReportSections = async (getClientApi, report_template_id, sections, workspace) => {
	const api = await getClientApi();
	const { data } = await api.patch(`reports/v1/${workspace.workspace_id}/templates/${report_template_id}/add-sections`, {
		sections
	});
	if (!stringIsNullOrEmpty(data.message)) {
		throw new Error(data.message);
	}
};

/**
 * Duplicates a report template
 * @param {*} param0
 * @param {report_template_id, report_template_name, banner_url, logo_url, workspace_id} param1
 * @param {number} workspace_id
 * @returns reportTemplate
 */
export const duplicateReportTemplate = async (getClientApi, { report_template_id, banner_url, logo_url }, workspace) => {
	const api = await getClientApi();

	const { data } = await api.post('reports/v1/duplicate', {
		report_template_id,
		workspace_id: workspace.workspace_id,
		banner_url,
		logo_url
	});

	if (!stringIsNullOrEmpty(data.message)) {
		throw new Error(data.message);
	}

	return deserializeReportTemplate(data, workspace);
};

/**
 * gets media items for a given section
 */
export const getSectionMediaItems = async (
	getClientApi,
	workspaceId,
	reportTemplateId,
	sectionId,
	lastRequestTime,
	sortOrder,
	sortDescending = false,
	pagination,
	cancelToken
) => {
	const api = await getClientApi();
	const { data } = await api.post(
		`reports/v1/${workspaceId}/templates/${reportTemplateId}/sections/${sectionId}/section-items`,
		{
			sortOrder,
			sortDescending,
			pagination,
			timestamp: !isNullOrUndefined(lastRequestTime) ? lastRequestTime.toISOString() : undefined
		},
		{ cancelToken }
	);

	if (!stringIsNullOrEmpty(data.message)) {
		throw new Error(data.message);
	}

	return data;
};

/**
 * Move item to another section in the same or a different report
 * @param {int} workspaceId workspace id
 * @param {int} moveToReportTemplateId report template to move to
 * @param {string} moveToSectionId section to move to
 * @param {int} sectionItemId section item to move
 * @param {string} sortOrder sort order of the moved-to section
 * @param {boolean} sortDescending sorting of the moved-to section
 * @param {boolean} reloadSectionItems if true, returns a list of section items for the moved-to section
 * @param {*} cancelToken cancel token
 * @returns
 */
export const moveSectionItem = async (
	getClientApi,
	workspaceId,
	moveToReportTemplateId,
	moveToSectionId,
	sectionItemId,
	sortOrder,
	sortDescending,
	reloadSectionItems,
	cancelToken
) => {
	const api = await getClientApi();
	const { data } = await api.post(
		`reports/v1/templates/${moveToReportTemplateId}/sections/${moveToSectionId}/section-items/${sectionItemId}/move`,
		{
			workspace_id: workspaceId,
			sortOrder,
			sortDescending,
			reloadSectionItems
		},
		{ cancelToken }
	);
	if (!stringIsNullOrEmpty(data.message)) {
		throw new Error(data.message);
	}
	return data;
};

/**
 * remvoves all items from a report template section
 * @param {*} param0
 * @param {*} reportTemplate
 */
export const removeReportTemplateSectionItems = async (
	getClientApi,
	workspace_id,
	report_template_id,
	report_template_section_id,
	cancelToken
) => {
	const api = await getClientApi();
	const { data } = await api.delete(
		`reports/v1/${workspace_id}/templates/${report_template_id}/sections/${report_template_section_id}/section-items`,
		{
			cancelToken
		}
	);

	if (!stringIsNullOrEmpty(data.message)) {
		throw new Error(data.message);
	}

	return data;
};

/**
 * saves a report template section item
 * @param {*} param0
 * @param {*} reportTemplate
 */
export const saveReportTemplateSectionItem = async (
	getClientApi,
	workspaceId,
	report_template_id,
	report_template_section_id,
	reportTemplateSectionItem,
	deletedSyndicationReportTemplateSectionItemIds,
	oldPrimaryReportTemplateSectionItemId
) => {
	const api = await getClientApi();
	const { data } = await api.patch(
		`reports/v1/${workspaceId}/templates/${report_template_id}/sections/${report_template_section_id}/section-items/${reportTemplateSectionItem.report_template_section_item_id}`,
		{
			reportTemplateSectionItem: serializeReportTemplateSectionItem(reportTemplateSectionItem),
			deletedSyndicationReportTemplateSectionItemIds,
			oldPrimaryReportTemplateSectionItemId
		}
	);
	if (!stringIsNullOrEmpty(data.message)) {
		throw new Error(data.message);
	}
	return data;
};

/**
 * creates a report template section item
 * @param {*} param0
 * @param {*} reportTemplate
 */
export const createReportTemplateSectionItem = async (
	getClientApi,
	workspaceId,
	report_template_id,
	report_template_section_id,
	reportTemplateSectionItem,
	pin_before_id = undefined,
	pin_after_id = undefined
) => {
	const api = await getClientApi();
	const { data } = await api.put(
		`reports/v1/${workspaceId}/templates/${report_template_id}/sections/${report_template_section_id}/section-items`,
		{
			workspaceId,
			pin_before_id,
			pin_after_id,
			reportTemplateSectionItem
		}
	);
	if (!stringIsNullOrEmpty(data.message)) {
		throw new Error(data.message);
	}
	if (!stringIsNullOrEmpty(data.pinningError)) {
		throw new Error(data.pinningError);
	}
	return data;
};

/**
 * removes a report template section item
 * @param {*} param0
 * @param {*} reportTemplate
 */
export const removeReportTemplateSectionItem = async (
	getClientApi,
	workspaceId,
	report_template_id,
	report_template_section_id,
	report_template_section_item_id
) => {
	const api = await getClientApi();
	const { data } = await api.delete(
		`reports/v1/${workspaceId}/templates/${report_template_id}/sections/${report_template_section_id}/section-items/${report_template_section_item_id}`
	);
	if (!stringIsNullOrEmpty(data.message)) {
		throw new Error(data.message);
	}
	return data;
};

/**
 * generates the unsubscribe link
 * @param {*} guid
 */
export const getUnsubscribeLink = (guid) => {
	const env = getEnvironmentName();
	if (env === 'dev2') {
		return `http://app.dev2.truescope.cloud/unsubscribe/${guid || ''}`;
	}

	if (env === 'prod') {
		return `http://app.truescope.com/unsubscribe/${guid || ''}`;
	}

	return '#';
};

/**
 * @param {*} subscriptions
 * @returns {number}
 */
export const countReportRecipients = (subscriptions) => {
	return subscriptions?.filter((subscription) => subscription.is_subscribed).length;
};

/**
 * creates an empty report schedule
 */
export const createEmptySchedule = (send = true) => {
	return {
		send,
		interval: scheduleIntervalOptionsLookup.daily,
		days_of_week: {
			monday: true,
			tuesday: true,
			wednesday: true,
			thursday: true,
			friday: true,
			saturday: false,
			sunday: false
		},
		day_of_month: 1,
		last_day_of_month: false,
		times_of_day: ['09:00'],
		times_of_day__moment: [moment('09:00', TIME_FORMAT)],
		timezone: getTimeZoneName(),
		notify_no_content: false,
		cron_expressions: ['0 0 9 ? * MON *'],
		description: send
			? 'This report is automatically sent to all subscribed recipients every Monday and Saturday at 9am and 5.30pm'
			: 'This report is not scheduled and is sent out by the report owner.'
	};
};

/**
 * creates an empty web setting
 */
export const createEmptyWebSetting = () => {
	return {
		include_spreadsheet_link: false,
		include_report_pdf_url: false,
		include_report_docx_url: false,
		header_text: 'Your report is ready to view.' // 15/07/2024 - this field is no longer in use since MY-3346. Consider to remove if no clients complain.
	};
};

/**
 * serializes a report template section item to be saved
 * @param {*} reportTemplateSectionItem
 */
export const serializeReportTemplateSectionItem = (reportTemplateSectionItem) => {
	const cloned = cloneDeep(reportTemplateSectionItem);
	delete cloned.resized_images;
	delete cloned.hosted_image_url;
	return cloned;
};

/**
 * serializes the report chart
 * @param {*} reportChart
 * @param {*} workspace
 * @param {*} appCache
 * @returns
 */
const serializeReportChart = (reportChart, workspace, appCache, user) => {
	reportChart.chartJson = serializeChart(reportChart.chartJson, workspace, appCache, user, true);
	return reportChart;
};

/**
 * deserializes the report chart
 */
const deserializeReportChart = (reportChart, workspace) => {
	reportChart.chartJson.search_filter = deserializeFilters(reportChart.chartJson.search_filter, workspace);
	return reportChart;
};

const serializeMediaItemsSection = (section, reportTemplate, workspace, appCache, user, isQuickShare) => {
	const hasItemDateFrom = !isNullOrUndefined(section.serialized_filters.item_date_from);
	const hasSocialEngagementFilter = filterHasValue(section.serialized_filters.social_engagement);
	if (hasSocialEngagementFilter) {
		if (!hasItemDateFrom) {
			section.serialized_filters.item_date_option = dateOptionsLookup.custom;
			section.serialized_filters.item_date_from = moment().utc().toDate().toISOString();
		}
	} else {
		delete section.serialized_filters.item_date_option;
		delete section.serialized_filters.item_date_from;
	}

	section.serialized_filters = serializeFilters(section.serialized_filters, workspace, appCache, user, isQuickShare);
	delete section.filter_options;
	//todo: stop junk from being stamped into the serialized filters
	delete section.serialized_filters?.junk;

	//only send through the items on clone
	if (!isNullOrUndefined(reportTemplate.report_template_id)) {
		delete section.items;
	}

	return section;
};

const serializeChartsSection = (section, workspace, appCache, user) => {
	return {
		charts: (section.charts = (section.charts || []).map((chart) => serializeReportChart(chart, workspace, appCache, user))),
		...section
	};
};

const serializeAiSummarySection = (section, workspace, appCache, user) => {
	return {
		...section,
		serialized_filters: serializeFilters(section.serialized_filters, workspace, appCache, user, true)
	};
};

/**
 * serializes a report template to be saved
 * @param {*} reportTemplate
 */
export const serializeReportTemplate = (reportTemplate, workspace, appCache, user, isQuickShare) => {
	let clone = cloneDeep(reportTemplate);

	clone.sections = (clone.sections || []).map((section) => {
		switch (section.section_type) {
			case reportSectionTypes.mediaItems:
				return serializeMediaItemsSection(section, reportTemplate, workspace, appCache, user, isQuickShare);
			case reportSectionTypes.charts:
				return serializeChartsSection(section, workspace, appCache, user);
			case reportSectionTypes.html:
				return section;
			case reportSectionTypes.aiSummary:
				return serializeAiSummarySection(section, workspace, appCache, user);
			default:
				throw new Error(`unknown report section type '${section.section_type}'`);
		}
	});

	delete clone.schedule?.times_of_day__moment;

	clone.name = reportTemplate.name?.trim() || '';

	// set current user timezone each time report template is saved
	clone.user_timezone = getTimeZoneName();

	clone.merge_us_short_date_format = US_SHORT_DATE_FORMAT;
	clone.merge_short_date_format = SHORT_DATE_FORMAT;
	clone.merge_long_date_format = getLocaleDateString();

	return clone;
};

/**
 * get total items in a report
 * @param {*} sections
 */
export const getItemCount = (sections) => {
	return (sections || []).map(({ items }) => (items || []).length).reduce((acc, cur) => acc + cur, 0);
};

export const getSectionIndex = (sectionId, reportTemplate) =>
	reportTemplate.sections.findIndex(({ section_id }) => sectionId === section_id);

export const getTimeZone = (reportTemplate) => {
	return (reportTemplate?.schedule || {}).timezone || reportTemplate?.user_timezone || 'Asia/Singapore';
};

export const scrollToSection = (sectionId) => {
	const element = document.querySelector(`.report-section[data-section-id="${sectionId}"]`);
	if (isNullOrUndefined(element)) {
		return;
	}
	element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
};

/**
 * creates a report template section item
 * @param {getClientApi} getClientApi
 * @param {number} report_template_id
 * @param {uuid} section_id
 * @param {object} chartJson
 */
export const addReportTemplateSectionChart = async (getClientApi, workspace_id, report_template_id, section_id, chart) => {
	const api = await getClientApi();
	const { data } = await api.put(`reports/v1/${workspace_id}/templates/${report_template_id}/sections/${section_id}/add-chart`, {
		chart
	});
	if (!stringIsNullOrEmpty(data.message)) {
		throw new Error(data.message);
	}
	return data;
};

/**
 * data source for charts
 */
export const chartDataSource = {
	workspace: 'workspace',
	reportSection: 'reportSection'
};

/**
 * data source for charts as options
 */
export const chartDataSourceOptions = [
	{
		label: 'Filtered content from your Workspace from a selected time period',
		value: chartDataSource.workspace
	},
	{
		label: 'Content appearing in one or more media item sections of this report',
		value: chartDataSource.reportSection
	}
];

/**
 * type of a report template section item
 */
export const reportTemplateSectionItemType = {
	mediaItem: 'MediaItem',
	externalItem: 'ExternalItem'
};

export const reportSettingsValidationRules = {
	name: ({ name }) => (stringIsNullOrEmpty(name) ? validationResults.required('Report name is required') : validationResults.ok()),
	reply_to: ({ reply_to }) =>
		stringIsNullOrEmpty(reply_to) ? validationResults.required('Please enter an email address') : validateEmail(reply_to)
};

export const sectionTitleValidationRule = {
	title: ({ title }) => (stringIsNullOrEmpty(title) ? validationResults.required('Section title is required') : validationResults.ok())
};

export const sectionDataSourceValidationRule = {
	data_source: ({ data_source }) => {
		if (isNullOrUndefined(data_source)) {
			return validationResults.required('Please choose what content to summarize');
		}
		return validationResults.ok();
	}
};

export const sectionReportTemplateSectionIdsValidationRule = {
	report_template_section_ids: ({ data_source, report_template_section_ids }) => {
		if (data_source === aiHtmlSectionDataSourceLookup.report && arrayIsNullOrEmpty(report_template_section_ids)) {
			return validationResults.required('Please choose at least one media item section');
		}
		return validationResults.ok();
	}
};

export const sectionUserQuestionValidationRule = {
	user_question: ({ data_source, user_question }) => {
		if (
			data_source === aiHtmlSectionDataSourceLookup.llm &&
			(isNullOrUndefined(user_question) || stringIsNullOrEmpty(user_question.trim?.()))
		) {
			return validationResults.required('Please enter a question for the Media Assistant');
		}
		return validationResults.ok();
	}
};

export const aiSummarySectionValidationRules = {
	...sectionTitleValidationRule,
	...sectionDataSourceValidationRule,
	...sectionReportTemplateSectionIdsValidationRule,
	...sectionUserQuestionValidationRule
};

/**
 *
 * @param {*} reportName
 * @param {*} showSnackbar
 * @returns
 */
export const isReportNameValid = (reportName, showSnackbar) => {
	if (stringIsNullOrEmpty(reportName.trim())) {
		if (!isNullOrUndefined(showSnackbar)) {
			showSnackbar('Unable to save a report with an empty title', snackbarVariants.error);
		}
		return false;
	}

	return true;
};

/**
 *
 * @param {*} reportSectionTitle
 * @param {*} setValidationState
 * @returns
 */
export const isReportSectionTitleValid = (reportSectionTitle, setValidationState) => {
	if (stringIsNullOrEmpty(reportSectionTitle.trim())) {
		if (!isNullOrUndefined(setValidationState)) {
			setValidationState(validationResults.required());
		}
		return false;
	}

	return true;
};

export const reportSyndicationOptionsLookup = {
	none: 'none', //no syndication occurs
	minimal: 'minimal', //this shows count only
	full: 'full' //this syndicates items into a group and shows a full source list
};
